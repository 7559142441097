<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            type="month"
            default="today"
            label="기준월"
            name="checkDt"
            v-model="searchParam.checkDt"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid1"
      title="설비 수선요청 통계"
      :gridHeightAuto="true"
      :filtering="false"
      :hideBottom="true"
      :usePaging="false"
      :columns="grid1.columns"
      :data="grid1.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="grid2"
      title="수선완료 통계"
      :gridHeightAuto="true"
      :filtering="false"
      :hideBottom="true"
      :usePaging="false"
      :columns="grid2.columns"
      :data="grid2.data"
    >
    </c-table>
    <c-table
      ref="grid3"
      title="신규요청 통계"
      :gridHeightAuto="true"
      :filtering="false"
      :hideBottom="true"
      :usePaging="false"
      :columns="grid3.columns"
      :data="grid3.data"
    >
    </c-table>
    <c-table
      ref="grid4"
      title="긴급요청 통계"
      :gridHeightAuto="true"
      :filtering="false"
      :hideBottom="true"
      :usePaging="false"
      :columns="grid4.columns"
      :data="grid4.data"
    >
    </c-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchParam: {
        plantCd: null,
        checkDt: '',
      },
      grid1: {
        columns: [
          {
            name: 'sumType',
            field: 'sumType',
            label: '구분',
            align: 'center',
            style: 'width:25%',
          },
          {
            name: 'prevNew',
            field: 'prevNew',
            label: '전월 누계',
            type: 'cost',
            style: 'width:25%',
          },
          {
            name: 'nowEnd',
            field: 'nowEnd',
            label: '당월 수선완료',
            type: 'cost',
            style: 'width:25%',
          },
          {
            name: 'nowNew',
            field: 'nowNew',
            label: '당월 신규수선',
            type: 'cost',
            style: 'width:25%',
          },
        ],
        data: [],
      },
      grid2: {
        columns: [],
        data: [],
      },
      grid3: {
        columns: [],
        data: [],
      },
      grid4: {
        columns: [],
        data: [],
      },
      editable: true,
      listUrl: '',
      prevMonth: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = '/api/wod/workorder/status/';
      this.getList();
    },
    getList() {
      this.prevMonth = this.$comm.getAddMonth(this.searchParam.checkDt + '-01', -1);
      this.getList01();
      this.getList02();
      this.getList03();
      this.getList04();
    },
    getList01() {
      this.$http.url = this.listUrl + '01';
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.grid1.columns[1], 'label', this.prevMonth + ' (전월)누계');
        this.$set(this.grid1.columns[2], 'label', this.searchParam.checkDt + ' (당월)수선완료');
        this.$set(this.grid1.columns[3], 'label', this.searchParam.checkDt + ' (당월)신규수선');
        this.grid1.data = _result.data
      },);
    },
    getList02() {
      this.$http.url = this.listUrl + '02';
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid2.columns = [];
        this.grid2.columns.push({
          fix: true,
          name: 'sumType',
          field: 'sumType',
          label: '구분',
          align: 'center',
          style: 'width:200px',
        })
        this.$_.forEach(_result.data.cols, item => {
          this.grid2.columns.push({
            name: item.equipmentTypeCd,
            field: item.equipmentTypeCd,
            label: item.equipmentTypeName,
            type: 'cost',
            style: 'width:160px',
          })
        })
        this.grid2.data = _result.data.datas;
      },);
    },
    getList03() {
      this.$http.url = this.listUrl + '03';
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid3.columns = [];
        this.grid3.columns.push({
          fix: true,
          name: 'sumType',
          field: 'sumType',
          label: '구분',
          align: 'center',
          style: 'width:200px',
        })
        this.$_.forEach(_result.data.cols, item => {
          this.grid3.columns.push({
            name: item.equipmentTypeCd,
            field: item.equipmentTypeCd,
            label: item.equipmentTypeName,
            type: 'cost',
            style: 'width:150px',
          })
        })
        this.grid3.data = _result.data.datas;
      },);
    },
    getList04() {
      this.$http.url = this.listUrl + '04';
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid4.columns = [];
        this.grid4.columns.push({
          fix: true,
          name: 'sumType',
          field: 'sumType',
          label: '구분',
          align: 'center',
          style: 'width:200px',
        })
        this.$_.forEach(_result.data.cols, item => {
          this.grid4.columns.push({
            name: item.equipmentTypeCd,
            field: item.equipmentTypeCd,
            label: item.equipmentTypeName,
            type: 'cost',
            style: 'width:120px',
          })
        })
        this.grid4.data = _result.data.datas;
      },);
    },
  }
};
</script>
